import { Icon } from "../icons/icon";
export function CreateComboboxContainer(props, expanded) {
    const container = document.createElement("div");
    container.classList.add("bg-body");
    container.classList.add("col-12", "col-sm-6", "col-lg-4");
    const combobox = document.createElement("div");
    combobox.classList.add("phd-facets-combobox-container");
    const button = document.createElement("button");
    button.classList.add("phd-facets-combobox-button");
    const label = document.createElement("div");
    label.innerText = props.label;
    // tabindex="0" role="combobox" aria-multiselect="true/false" aria-expanded="true/false" "aria-activedescendant"
    const leafContainer = document.createElement("div");
    leafContainer.classList.add("phd-facets-combobox-leaf-container");
    leafContainer.ariaHidden = "true";
    combobox.appendChild(button);
    combobox.appendChild(leafContainer);
    combobox.ariaExpanded = "false";
    combobox.role = "tree";
    combobox.ariaMultiSelectable = "true";
    button.appendChild(label);
    button.appendChild(Icon.arrow("cta"));
    container.appendChild(combobox);
    expanded.subscribeAndRun((val) => {
        leafContainer.style.display = val ? "flex" : "none";
    });
    button.addEventListener("click", () => {
        expanded.value = !expanded.value;
        combobox.ariaExpanded = `${expanded.value}`;
        leafContainer.ariaHidden = `${!expanded.value}`;
    });
    combobox.addEventListener("focusout", (e) => {
        if (e.relatedTarget && container.contains(e.relatedTarget))
            return;
        expanded.value = false;
        props.onBlur();
    });
    return { container, leafContainer };
}
export function translate(input) {
    const language = document.documentElement.lang === "de" ? "de" : "en";
    return input[language] ?? input["en"] ?? "";
}
export const set = {
    merge(setA, setB) {
        const res = new Set();
        setA.forEach((v) => res.add(v));
        setB.forEach((v) => res.add(v));
        return res;
    },
    toArray(set) {
        const s = [];
        set.forEach((v) => s.push(v));
        return s;
    },
    filter(set, predicate) {
        const s = new Set();
        set.forEach((v) => predicate(v) && s.add(v));
        return s;
    },
};
export const uniqueId = (function idGenerator() {
    let id = 0;
    return function uniqueId() {
        return `id-${id++}`;
    };
})();
