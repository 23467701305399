import { Observable } from "../../../utils/observable";
import { CreateComboboxContainer, uniqueId } from "../utils";
export class CheckboxGroup {
    selected;
    onChange;
    state;
    constructor(selected, onChange, state) {
        this.selected = selected;
        this.onChange = onChange;
        this.state = state;
    }
    render(result) {
        const expanded = Observable(false);
        const { container, leafContainer } = CreateComboboxContainer({
            label: result.label,
            onBlur: () => { },
        }, expanded);
        result.data.forEach((entry, index) => {
            leafContainer.appendChild(this.renderCheckboxGroupLeaf(entry, index));
        });
        return container;
    }
    toggle(value) {
        const state = new Set(this.selected.value);
        if (state.has(value))
            state.delete(value);
        else
            state.add(value);
        this.onChange(state);
    }
    renderCheckboxGroupLeaf(entry, index) {
        const leaf = document.createElement("button");
        leaf.classList.add("phd-facets-combobox-checkbox-group-container");
        leaf.ariaSelected = "false";
        const id = uniqueId();
        const input = document.createElement("input");
        input.classList.add("phd-facets-combobox-checkbox-group-checkbox");
        input.type = "checkbox";
        input.id = id;
        input.value = entry.value;
        const label = document.createElement("label");
        label.classList.add("phd-facets-combobox-checkbox-group-label");
        this.state?.subscribeAndRun((res) => {
            let v = res.data[index];
            label.innerText = `${v.label} (${v.count})`;
        });
        label.htmlFor = id;
        leaf.appendChild(input);
        leaf.appendChild(label);
        this.selected.subscribeAndRun(function (val) {
            const selected = val?.has(entry.value) ?? false;
            input.checked = selected;
            leaf.ariaSelected = `${selected}`;
        });
        leaf.addEventListener("click", () => this.toggle(entry.value));
        return leaf;
    }
}
