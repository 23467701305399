import { Observable } from "../../../utils/observable";
import { Icon } from "../../icons/icon";
import { CreateComboboxContainer, set, uniqueId } from "../utils";
export class TreeSelect {
    selected;
    onChange;
    state;
    map;
    constructor(selected, onChange, state) {
        this.selected = selected;
        this.onChange = onChange;
        this.state = state;
        this.map = new Map();
        this.generateDynamicDataMap();
    }
    render(component) {
        const expanded = Observable(false);
        const { container, leafContainer } = CreateComboboxContainer({
            label: component.label,
            onBlur: () => { },
        }, expanded);
        component.data.forEach((item, index) => leafContainer.appendChild(new Leaf(expanded, this, index).render(item)));
        return container;
    }
    generateDynamicDataMap() {
        const generateDeepList = (arr, parentIndex) => arr.forEach((e, i) => {
            let key = parentIndex ? `${parentIndex}-${i}` : `${i}`;
            this.map.set(key, { ...e, children: [] });
            if (e.children?.length > 0) {
                generateDeepList(e.children, key);
            }
        });
        this.state?.subscribeAndRun((component) => {
            if (!component)
                return;
            generateDeepList(component.data);
        });
    }
}
export class Leaf {
    show;
    owner;
    index;
    parent;
    expanded = Observable(false);
    constructor(show, owner, index, parent) {
        this.show = show;
        this.owner = owner;
        this.index = index;
        this.parent = parent;
    }
    get key() {
        return this.parent
            ? `${this.parent.key}-${this.index}`
            : `${this.index}`;
    }
    static deepestChildren(facet) {
        if (facet.children?.length === 0 || !facet.children)
            return new Set([facet.value]);
        const children = facet.children ?? [];
        return children.reduce((p, c) => set.merge(p, this.deepestChildren(c)), new Set());
    }
    render(data, tab) {
        const container = document.createElement("div");
        container.classList.add("phd-facets-combobox-tree-select-leaf-container");
        const button = document.createElement("button");
        button.classList.add("phd-facets-combobox-tree-select-leaf-button");
        // aria attributes
        button.role = "treeitem";
        button.ariaSelected = "false";
        button.ariaExpanded = "false";
        button.style.paddingLeft = `${(tab ?? 0) * 20 + 6}px`;
        const newId = uniqueId();
        const input = document.createElement("input");
        input.id = newId;
        input.type = "checkbox";
        const text = document.createElement("label");
        text.classList.add("phd-facets-combobox-tree-select-leaf-text");
        text.htmlFor = newId;
        text.innerText = `${data?.label} (${data?.count})`;
        this.owner.state?.subscribeAndRun(() => {
            const liveData = this.owner.map.get(this.key);
            text.innerText = `${liveData?.label} (${liveData?.count})`;
        });
        const childLeafs = [];
        for (let i = 0; i < data.children?.length; i++)
            childLeafs.push(new Leaf(this.expanded, this.owner, i, this).render(data.children[i], (tab ?? 0) + 1));
        button.appendChild(input);
        button.appendChild(text);
        container.appendChild(button);
        const childrenSet = Leaf.deepestChildren(data);
        const childrenArr = set.toArray(childrenSet);
        const hasChildren = childrenArr.length > 1;
        if (hasChildren)
            button.appendChild(Icon.arrow("body"));
        container.append(...childLeafs);
        const setCheckboxState = (val) => {
            // reset checkbox state
            input.checked = false;
            input.indeterminate = false;
            if (!val)
                return;
            // if all children are selected, check the box
            if (childrenArr.every((v) => val.has(v)))
                return (input.checked = true);
            // if some children are selected, set indeterminate state
            if (childrenArr.some((v) => val.has(v)))
                return (input.indeterminate = true);
        };
        this.owner.selected.subscribeAndRun((selected) => {
            setCheckboxState(selected);
            button.ariaSelected = `${childrenArr.every((v) => selected?.has(v))}`;
        });
        this.show.subscribeAndRun(function (val) {
            container.style.display = val ? "flex" : "none";
            container.ariaHidden = `${!val}`;
        });
        this.expanded.subscribeAndRun(function (val) {
            val
                ? container.classList.add("expanded")
                : container.classList.remove("expanded");
            container.ariaExpanded = `${val}`;
            button.ariaExpanded = `${val}`;
        });
        const handleSelect = () => {
            const alreadySelected = childrenArr.every((v) => this.owner.selected.value?.has(v));
            button.ariaSelected = `${!alreadySelected}`;
            if (!this.owner.selected.value)
                this.owner.selected.value = new Set();
            if (alreadySelected) {
                this.owner.onChange(set.filter(this.owner.selected.value, (v) => !childrenSet.has(v)));
                return;
            }
            this.owner.onChange(set.merge(this.owner.selected.value, childrenSet));
        };
        input.addEventListener("click", (e) => {
            e.stopPropagation();
            handleSelect();
        });
        button.addEventListener("click", () => {
            if (hasChildren)
                this.expanded.value = !this.expanded.value;
            else
                handleSelect();
        });
        return container;
    }
}
