import { computed } from "../../../utils/observable";
import { Icon } from "../../icons/icon";
import { set, translate } from "../utils";
import { CheckboxGroup } from "./checkbox-group";
import { Toggle } from "./toggle";
import { Leaf, TreeSelect } from "./tree-select";
export class Facets {
    useSearchParams;
    apiResult;
    constructor(useSearchParams, apiResult) {
        this.useSearchParams = useSearchParams;
        this.apiResult = apiResult;
    }
    createFilterResetButton(onClick) {
        const button = document.createElement("div");
        button.classList.add("phd-facets-reset-button", "flex", "gap-1", "justify-content-center", "align-items-center");
        const closeIcon = Icon.closeIcon();
        closeIcon.style.width = "1rem";
        closeIcon.style.height = "1rem";
        closeIcon.style.color = "var(--bs-cta)";
        const span = document.createElement("span");
        span.classList.add("phd-facets-reset-button-text");
        button.appendChild(span);
        button.appendChild(closeIcon);
        button.addEventListener("click", onClick);
        return { button, text: span };
    }
    filtersInTreeSelectSubCategory(val, item) {
        return set.filter(Leaf.deepestChildren(item), (v) => val?.has(v) ?? false);
    }
    createTreeSelectResetFilterButtons(facet) {
        const [state, setState] = this.useSearchParams.create(facet.name, true);
        return facet.data.map((item) => {
            const { button, text } = this.createFilterResetButton(() => {
                const setFilters = this.filtersInTreeSelectSubCategory(state.value, item);
                setState(set.filter(state.value || new Set(), (v) => !setFilters.has(v)));
            });
            state.subscribeAndRun((val) => {
                const setFilters = this.filtersInTreeSelectSubCategory(val, item);
                if (setFilters.size === 0 || val === null)
                    return (button.style.display = "none");
                else
                    button.style.display = "flex";
                const label = `${facet.label}: ${setFilters.size} ${item.label ? " in " + item.label : ""}`;
                text.innerText = label;
            });
            return button;
        });
    }
    createToggleFilterButton(facet) {
        const [state, setState] = this.useSearchParams.create(facet.name, false);
        const { button, text } = this.createFilterResetButton(() => setState(null));
        state.subscribeAndRun((val) => {
            if (val !== "true")
                return (button.style.display = "none");
            button.style.display = "flex";
            text.innerText = facet.label;
        });
        return button;
    }
    createCheckboxGroupFilterButton(facet) {
        const [state, setState] = this.useSearchParams.create(facet.name, true);
        const { button, text } = this.createFilterResetButton(() => setState(null));
        state.subscribeAndRun((val) => {
            if (val === null || val.size === 0)
                return (button.style.display = "none");
            button.style.display = "flex";
            text.innerText = `${facet.label}: ${val.size} ${translate({
                de: "Filter aktiv",
                en: "Filter active",
            })}`;
        });
        return button;
    }
    createSingleFilterButton(key, label) {
        const resetButton = this.createFilterResetButton(() => {
            const nextUrlSearchParams = new URLSearchParams(this.useSearchParams.params.value);
            nextUrlSearchParams.delete(key);
            this.useSearchParams.params.value = nextUrlSearchParams;
        });
        this.useSearchParams.create(key)[0].subscribeAndRun((val) => {
            const { button, text } = resetButton;
            if (!val)
                return (button.style.display = "none");
            button.style.display = "flex";
            text.innerText = `${label}: ${val}`;
        });
        return resetButton.button;
    }
    createClearAllButton() {
        const clearButton = document.createElement("button");
        clearButton.classList.add("phd-facets-clear-button");
        clearButton.innerText = translate({
            de: "Filter zurücksetzen",
            en: "Reset filters",
        });
        this.useSearchParams.params.subscribeAndRun((val) => {
            const disabled = Array.from(val.values()).length === 0;
            clearButton.disabled = disabled;
            if (disabled)
                clearButton.classList.add("d-none");
            else
                clearButton.classList.remove("d-none");
        });
        clearButton.addEventListener("click", () => {
            this.useSearchParams.params.value = new URLSearchParams();
        });
        return clearButton;
    }
    createResetFilterLabel() {
        const label = document.createElement("div");
        label.classList.add("phd-facets-clear-label");
        label.innerText = translate({
            de: "Aktive Filter:",
            en: "Active Filters:",
        });
        this.useSearchParams.params.subscribeAndRun((val) => {
            if (Array.from(val.values()).length === 0) {
                return label.classList.add("d-none");
            }
            label.classList.remove("d-none");
        });
        return label;
    }
    createResetButtons(result) {
        return [
            this.createResetFilterLabel(),
            this.createSingleFilterButton("phd-q", result.filters.search.label),
            this.createSingleFilterButton("phd-s", result.filters.sort.label),
            ...result.filters.facets.items.flatMap((facet) => {
                switch (facet.type) {
                    case "treeSelect": {
                        return this.createTreeSelectResetFilterButtons(facet);
                    }
                    case "toggle": {
                        return this.createToggleFilterButton(facet);
                    }
                    case "checkboxGroup": {
                        return this.createCheckboxGroupFilterButton(facet);
                    }
                }
            }),
        ];
    }
    renderFacet(item, index, state) {
        let computable = computed([state], (arg) => {
            return arg?.filters.facets.items[index];
        });
        switch (item.type) {
            case "treeSelect": {
                const state = this.useSearchParams.create(item.name, true);
                return new TreeSelect(...state, computable).render(item);
            }
            case "toggle": {
                const state = this.useSearchParams.create(item.name);
                return new Toggle(...state, computable).render(item);
            }
            case "checkboxGroup": {
                const state = this.useSearchParams.create(item.name, true);
                return new CheckboxGroup(...state, computable).render(item);
            }
            default: {
                console.error("Unknown facet type", item);
                return document.createElement("span");
            }
        }
    }
    render() {
        const result = this.apiResult.value;
        const wrapper = document.createElement("div");
        wrapper.classList.add("phd-facets-wrapper");
        const mobileExpandButton = document.createElement("button");
        mobileExpandButton.classList.add("phd-facets-mobile-expand-button");
        mobileExpandButton.innerText = translate({
            de: "weitere Filter einblenden",
            en: "show more filters",
        });
        mobileExpandButton.addEventListener("click", () => {
            wrapper.classList.toggle("expanded");
        });
        mobileExpandButton.prepend(Icon.arrow("body"));
        wrapper.appendChild(mobileExpandButton);
        const headline = document.createElement("h3");
        headline.classList.add("phd-facets-headline", "h3");
        headline.innerText = translate({ de: "Filter nach", en: "Filter by" });
        const facetsContainer = document.createElement("div");
        facetsContainer.classList.add("phd-facets-container", "row");
        wrapper.appendChild(headline);
        wrapper.appendChild(facetsContainer);
        if (result)
            result.filters.facets.items.forEach((facet, index) => {
                facetsContainer.appendChild(this.renderFacet(facet, index, this.apiResult));
            });
        const resetContainer = document.createElement("div");
        resetContainer.classList.add("phd-facets-reset-container");
        const resetWrapper = document.createElement("div");
        resetWrapper.classList.add("phd-facets-reset-wrapper");
        if (result) {
            for (const button of this.createResetButtons(result))
                resetContainer.appendChild(button);
        }
        resetWrapper.appendChild(resetContainer);
        resetWrapper.appendChild(this.createClearAllButton());
        wrapper.appendChild(resetWrapper);
        return wrapper;
    }
}
